<template>
  <div class="resend__body">
    <actions-button
      :appearance="$pepper.Appearance.PRIMARY"
      class="resend__action"
      :disabled="resend"
      :loading="$user.loading"
      @click="onResend"
    >{{ $t('conn3ct-wallet.resend_email_action') }}</actions-button>

    <button
      @click="onCancel"
      class="resend__cancel"
    >{{ $t('conn3ct-wallet.cancel') }}</button>
  </div>
</template>

<script>
export default {
  name: 'SaylConn3ctEmail',

  data() {
    return {
      resend: false,
      loading: false,
      isOptional: false
    }
  },

  methods: {
    onCancel() {
      this.$emit('next', this.isOptional)
    },

    onResend() {
      this.$user.resendVerificationEmail()
        .then(() => {
          this.resend = true
          setTimeout(() => this.resend = false, 30 * 1000)
        })
        .catch((e) => $console.error(e))
    },

    onRefresh() {
      this.loading = true
      this.$user.getUser()
        .then(user => {
          this.setLocale(user.lang)
          if(this.$basil.get(this.$user, 'user.emailVerified')) {
            this.$emit('next', this.isOptional)
          }
        })
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    },
  },

  created() {
    this.isOptional = this.$route.params?.isOptional ?? false
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>
